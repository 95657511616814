import React, { useEffect, useState, useCallback, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import QRCode from "qrcode.react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ButtonBase from "@material-ui/core/ButtonBase";

import RefreshIcon from "@material-ui/icons/Refresh";

import Spinner from "components/Spinner";

import { fb } from "services/firebase";
import { hcn } from "services/hcn";

const useStyles = makeStyles((theme) => ({
  container: {
    height: ({ size, padding }) => size + theme.spacing(padding),
    width: ({ size, padding }) => size + theme.spacing(padding),
    borderRadius: ({ borderRadius }) => theme.spacing(borderRadius),
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
}));

function QRLogin(props) {
  const { size = 250, timeout = false, onRefresh = () => {} } = props;
  const classes = useStyles(props);
  const [tel, setTel] = useState("");
  const [code, setCode] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [channel, setChannel] = useState(null);
  const confirmationResult = useRef(null);
  const verifier = useRef(null);
  const recapchaRef = useRef();

  const getLoginChannel = useCallback(async () => {
    try {
      const { channel: data } = await hcn.doGetLoginChannel();
      setChannel(data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    verifier.current = new fb.auth.RecaptchaVerifier(recapchaRef.current, {
      size: "invisible",
    });
    getLoginChannel();
    return () => {
      verifier.current.clear();
    };
  }, [getLoginChannel]);

  useEffect(() => {
    if (!channel) {
      return;
    }
    const ref = fb.database().ref("login").child(channel.id);
    ref.on("value", (data) => {
      const val = data?.val();
      if (val?.tel) {
        setTel(val.tel);
      }
      if (val?.code) {
        setCode(val.code);
      }
    });
    return () => ref.off();
  }, [channel]);

  const handleSubmitOTP = useCallback(async () => {
    try {
      await confirmationResult.current.confirm(code);
      await hcn.doUpdateLoginChannelStatus({
        id: channel?.id,
        input: { success: true },
      });
      setOtpSent(false);
    } catch (err) {
      console.log(err);
    }
  }, [channel?.id, code]);

  const handleSubmit = useCallback(async () => {
    try {
      await verifier.current.verify();
      await fb
        .auth()
        .signInWithPhoneNumber(tel, verifier.current)
        .then((result) => {
          confirmationResult.current = result;
          setOtpSent(true);
        });
      await hcn.doUpdateLoginChannelStatus({
        id: channel?.id,
        input: { otp: true },
      });
    } catch (err) {
      console.log(err);
    }
  }, [channel?.id, tel]);

  const handleOnRefresh = useCallback(() => {
    onRefresh();
  }, [onRefresh]);

  useEffect(() => {
    if (tel) {
      handleSubmit();
    }
  }, [handleSubmit, tel]);

  useEffect(() => {
    if (code && otpSent) {
      handleSubmitOTP();
    }
  }, [code, otpSent, handleSubmitOTP]);

  return (
    <div className={classes.container}>
      {channel?.id ? <QRCode size={size} value={channel.url} /> : <Spinner />}
      {timeout && (
        <Box
          position="absolute"
          right={0}
          top={0}
          left={0}
          bottom={0}
          bgcolor={"background.overlay.light"}
          p={4.5}
        >
          <ButtonBase
            style={{ height: "100%", borderRadius: "50%", overflow: "hidden" }}
            // disableRipple
            onClick={handleOnRefresh}
          >
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              bgcolor="branding.green"
              height="100%"
              borderRadius="50%"
              alignItems="center"
              justifyContent="center"
              color="white"
              p={1.5}
            >
              <RefreshIcon style={{ fontSize: 48, marginBottom: 4 }} />
              <Typography style={{ lineHeight: 1.4 }} variant="button">
                Click to refresh qr code
              </Typography>
            </Box>
          </ButtonBase>
        </Box>
      )}
      <div ref={recapchaRef} id="recaptcha-container" />
    </div>
  );
}

QRLogin.defaultProps = {
  size: 250,
  padding: 4,
  borderRadius: 2,
};

export default QRLogin;
