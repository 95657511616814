import * as ROUTE from "./constants";

import HomeIcon from "@material-ui/icons/Home";
import TimerIcon from "@material-ui/icons/Timer";
import InboxIcon from "@material-ui/icons/Inbox";

const mainMenu = {
  branding: {
    id: "mainMenuBranding",
    title: "Human Cloud",
    icon: undefined,
    route: ROUTE.SPACES,
  },
  home: {
    id: "mainMenuDashboard",
    title: "Dashboard",
    icon: <HomeIcon />,
    route: ROUTE.DASHBOARD,
  },
  categories: {
    data: [
      {
        id: "id1",
        title: "Talent",
        defaultOpen: true,
        data: [
          {
            id: "id11",
            title: "JobPosts",
            icon: <TimerIcon />,
            route: ROUTE.JOB_POSTS,
          },
          {
            id: "id12",
            title: "Upskilling",
            icon: <TimerIcon />,
            route: "/upskilling",
          },
          {
            id: "id13",
            title: "Mentoring",
            icon: <TimerIcon />,
            route: "/mentoring",
          },
        ],
      },
      {
        id: "id2",
        title: "Engage & Monitor",
        defaultOpen: false,
        data: [
          {
            id: "id21",
            title: "Profile",
            icon: <TimerIcon />,
            route: "/profile",
          },
          {
            id: "id22",
            title: "SI Credits",
            icon: <TimerIcon />,
            route: "/performance_review",
          },
          {
            id: "id24",
            title: "Talent graph",
            icon: <TimerIcon />,
            route: "/talent-graph",
          },
        ],
      },
      {
        id: "id3",
        title: "Analytics",
        defaultOpen: false,
        data: [
          {
            id: "id31",
            title: "Industry",
            icon: <TimerIcon />,
            route: "/analytics/industry",
          },
          {
            id: "id32",
            title: "Skills",
            icon: <TimerIcon />,
            route: "/analytics/skills",
          },
          {
            id: "id33",
            title: "Market",
            icon: <TimerIcon />,
            route: "/analytics/market",
          },
        ],
      },
    ],
  },
  global: {
    data: [
      {
        id: "mainInboxDashboard",
        title: "Inbox",
        icon: <InboxIcon style={{ fontSize: 26 }} />,
        route: ROUTE.MY_INBOX,
      },
    ],
  },
  manage: {
    id: "mainManageDashboard",
    title: "Manage",
  },
};

export { mainMenu };
