import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import Spinner from "components/Spinner";

const useStyles = makeStyles({
  fsc: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

function ScreenLoader(props) {
  const { size, loadingDelay, onChange, insetTop, ...rest } = props;
  const classes = useStyles();
  return (
    <Box className={classes.fsc} {...rest}>
      <Spinner
        size={size}
        loadingDelay={loadingDelay}
        loaderOnChange={onChange}
        center
        insetTop={insetTop}
      />
    </Box>
  );
}

export default ScreenLoader;
