import { gql } from "graphql-request";

export const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

export const GET_CURRENT_USER = gql`
  query getCurrentUserQuery {
    currentUser: GetCurrentUser {
      uid
      displayName
      email
      photoURL
      createdAt
      theme
      displayCurrency
      acceptRemote
      lastseenAt
      lastAccount
      primaryLocDescription
      primaryLocGooglePlaceId
      primaryLocMaxDistance
      businesses {
        Business {
          xid
          name
          jobposts {
            xid
            title
            status
            createdAt
          }
        }
      }
    }
  }
`;

export const GET_BUSINESS = gql`
  query getBusinessQuery($xid: ID!) {
    b: Business(xid: $xid) {
      xid
      name
      photoURL
      jobposts {
        xid
        title
        status
        createdAt
        updatedAt
        media {
          URI
          type
          xid
        }
      }
    }
  }
`;

export const GET_PROCESSING_STATE = gql`
  query globalProcessingState {
    processing @client
  }
`;

export const GET_CHALLENGE_SESSION = gql`
  query challengeGetByQuestId($id: ID!, $taskIds: [ID!]!) {
    challengeSession: ChallengeSessionGet(id: $id, taskIds: $taskIds) {
      id
      sessionId
      data {
        id
        type
        challenge {
          text
        }
        options(sort: DESC, settings: { ipip: { options: 5 } }) {
          id
          text
          score
        }
      }
    }
  }
`;

export const QUEST_MAP_GET = gql`
  query getQuestmap($uid: ID!) {
    questMap: QuestMapGet(uid: $uid, setMap: { map: CYBER }) {
      id
      title
      avatarUrl
      status(uid: $uid) {
        completed
      }
      data {
        id
        title
        avatarUrl
        cardUrl
        cardColor
        prev
        next
        type
        status(uid: $uid) {
          total
          current
          progress
          completed
          # remainders
          random
        }
        isLocked(uid: $uid)
        data(uid: $uid) {
          id
          title
          prev
          next
          type
          rangeFrom
          rangeTo
          status {
            isLocked
            completed
            total
            progress
          }
        }
      }
    }
  }
`;

export const GET_REACHOUT_PUBLISH = gql`
  query getReachoutById($reachoutId: ID!) {
    ro: Reachout(reachoutId: $reachoutId) {
      reachoutId
      status
      budget
      duration
      total
      reach
      connect
      upskill
      split
      impactScore
    }
  }
`;

export const GET_REACHOUT = gql`
  query getReachout($reachoutId: ID!) {
    ro: Reachout(reachoutId: $reachoutId) {
      reachoutId
      createdAt
      updatedAt
      status
      budget
      starts
      duration
      expire
      total
      reach
      connect
      upskill
      split
      impactScore
      reachouter {
        xid
        displayName
        photoURL
        name
        address
        city
        state
        country
      }
      attachment {
        xid
        title
        titleXid
        status
        createdAt
        updatedAt
        type
        description
        maxSalary
        minSalary
        currency
        locDescription
        country
        acceptRemote
        salaryType
        media {
          xid
          type
          URI
          alt
          poster
        }
      }
    }
  }
`;

export const GET_HOME_TIMELINE = gql`
  query {
    tlh: timelineHomeGet {
      match {
        __typename
        matchId
        createdAt
        introductionRequested
        claim {
          __typename
          claimId
          amount
          claimedAt
          expireAt
        }
        ro: reachout {
          __typename
          reachoutId
          impactScore
          expire
          reach
          upskill
          split
          budget
          reachouter {
            xid
            name
            photoURL
          }
          attachment {
            xid
            title
            titleXid
            status
            createdAt
            updatedAt
            type
            description
            maxSalary
            minSalary
            currency
            locDescription
            country
            acceptRemote
            salaryType
            media {
              xid
              type
              URI
              alt
              poster
            }
          }
        }
      }
    }
  }
`;

export const GET_WALLET = gql`
  query getWallet($uid: ID!) {
    wallet: WalletUserGet(uid: $uid) {
      id
      alias
      description
      avatarURL
      balance
      tx {
        id
        symbol
        amount
        createdAt
        message
      }
    }
  }
`;

export const GET_IP_SCORE_BY_SCALE = gql`
  query ipScoreByScale($scaleId: ID!) {
    s: getIpScore(scaleId: $scaleId) {
      id
      result {
        name
        id
        score
        facet {
          name
          id
          score
        }
      }
      facetList {
        name
        id
        score
      }
    }
  }
`;

export const FUZZY_SEARCH_JOBTITLES = gql`
  query fuzzySearchJobTitles($title: String!) {
    suggestions: suggestJobTitle(title: $title) {
      __typename
      xid
      title
    }
  }
`;

export const GET_BRAINTREE_CLIENT_TOKEN = gql`
  query getBraintreeClientToken($vaultOwnerId: VaultOwnerId!) {
    customer: getPaymentToken(vaultOwnerId: $vaultOwnerId) {
      token
    }
  }
`;

export const GET_PAYMENT_METHODS = gql`
  query getPaymentMethodsbyId($vaultOwnerId: VaultOwnerId!) {
    paymentMethods: getPaymentMethods(vaultOwnerId: $vaultOwnerId) {
      bin
      cardType
      cardholderName
      createdAt
      updatedAt
      expirationMonth
      expirationYear
      imageUrl
      token
      maskedNumber
      expirationDate
      last4
      default
    }
  }
`;

// Job Post
export const JOB_POST_GET = gql`
  query jopPostGet($id: ID!) {
    jobPost: JobPostGet(id: $id) {
      id
      title
      createdAt
      data
    }
  }
`;

export const JOB_POSTS_GET = gql`
  query jobPostsGet($filter: jobPostFilter, $limit: Int, $sort: sort) {
    jobPosts: JobPostsGet(filter: $filter, limit: $limit, sort: $sort) {
      id
      title
      createdAt
    }
  }
`;

export const JOB_POST_INTROS_GET = gql`
  query introductionsGet($id: ID!, $jobPostId: ID!) {
    introductions: JobPostIntroductions(id: $id, jobPostId: $jobPostId) {
      id
      profile {
        id
        displayName
      }
      contact {
        email
        telephone
      }
      location {
        residency
      }
      claims
    }
  }
`;
