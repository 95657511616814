import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

import Spinner from "components/Spinner";

const styles = (theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: theme.sizes.drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  main: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    background: theme.palette.background.primary,
    height: "100vh",
    minWidth: 375,
  },
  navigatorShell: {
    width: theme.sizes.drawerWidth,
    backgroundColor: theme.palette.background.menu[0],
    height: "100vh",
  },
});

function Initializing(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        <Hidden xsDown implementation="css">
          <div className={classes.navigatorShell} />
        </Hidden>
      </nav>
      <div className={classes.app}>
        <main className={classes.main}>
          <Spinner center loadingDelay={1800} />
        </main>
      </div>
    </div>
  );
}

export default withStyles(styles)(Initializing);
