import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

function NotFound(props) {
  const { pageTitle, actionPath, actionTitle } = props;

  return (
    <div>
      <h1>{pageTitle}</h1>
      <Button component={Link} to={actionPath}>
        {actionTitle}
      </Button>
    </div>
  );
}

export default NotFound;
