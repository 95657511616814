import React from "react";
import { Route } from "react-router-dom";

function RouteWithLayout(props) {
  const { children, showHeader = false, layout: Layout, ...rest } = props;
  return (
    <Route {...rest}>
      <Layout showHeader={showHeader}>{children}</Layout>
    </Route>
  );
}

export default RouteWithLayout;
