import React, { useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import OffsetHeader from "components/OffsetHeader";
import Card from "@material-ui/core/Card";

import QRLogin from "containers/auth/QRLogin";
import PhoneNumberLogin from "containers/auth/PhoneNumberLogin";

import logo from "assets/img/logo-green.png";
import logoText from "assets/img/logo-text.png";

import Instructions from "./Instructions";

const useStyles = makeStyles((theme) => {
  return {
    marginRight: {
      marginRight: theme.spacing(1.5),
    },
    backgroundColor: {
      backgroundColor: theme.palette.background.menu[0],
    },
    lowerBackground: {
      backgroundColor: theme.palette.background.default,
      flex: 1,
      width: "100%",
    },
    card: {
      display: "inline-table",
    },
  };
});

function Login(props) {
  const classes = useStyles(props);
  const [qrAuth, setQrAuth] = useState(true);

  const handleOnChangeAuth = useCallback((qr) => {
    setQrAuth(qr);
  }, []);

  const renderAuth = () => {
    return (
      <>
        <Box mr={{ xs: 0, sm: 4, md: 6 }}>
          <Instructions onChangeAuth={handleOnChangeAuth} qrAuth={qrAuth} />
        </Box>
        <Box mb={{ xs: 2, sm: 0 }}>
          {qrAuth ? (
            <QRLogin
              size={250}
              padding={0}
              borderRadius={0}
              timeout={false} // TODO: handle timeout, cancel & refresh channel
              onRefresh={() => {
                console.log("REFRESH QR");
              }}
            />
          ) : (
            <PhoneNumberLogin />
          )}
        </Box>
      </>
    );
  };

  return (
    <Box overflow="auto" className={classes.backgroundColor}>
      <Helmet>
        <title>HumanCloud</title>
      </Helmet>
      <AppBar position="absolute" color="transparent" elevation={0}>
        <Box py={1}>
          <Toolbar>
            <img
              className={classes.marginRight}
              src={logo}
              height="28"
              width="auto"
              alt="Human Cloud Logo"
            />
            <img src={logoText} height="16" width="auto" alt="Human Cloud" />
          </Toolbar>
        </Box>
      </AppBar>
      <OffsetHeader />
      <Box
        position="relative"
        display="flex"
        minHeight="100vh"
        flexDirection="column"
        alignItems="center"
        paddingTop={{ xs: "8vh", sm: "20vh" }}
        flex={1}
      >
        <Box
          top={{ xs: "3vh", sm: "10vh" }}
          left={0}
          right={0}
          position="absolute"
          display="flex"
          justifyContent="center"
          px={3}
        >
          <Card elevation={10} className={classes.card}>
            <Box
              display="flex"
              p={{ xs: 3, sm: 6 }}
              flexDirection={{ xs: "column-reverse", sm: "row" }}
              alignItems={{ xs: "center", sm: "flex-start" }}
            >
              {renderAuth()}
            </Box>
          </Card>
        </Box>
        <div className={classes.lowerBackground} />
      </Box>
    </Box>
  );
}

export default Login;
