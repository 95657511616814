export const JOB_POST_CREATE_REQUEST = "JOB_POST_CREATE_REQUEST";
export const JOB_POST_CREATE_SUCCESS = "JOB_POST_CREATE_SUCCESS";
export const JOB_POST_CREATE_FAILURE = "JOB_POST_CREATE_FAILURE";

export const JOB_POST_UPDATE_REQUEST = "JOB_POST_UPDATE_REQUEST";
export const JOB_POST_UPDATE_SUCCESS = "JOB_POST_UPDATE_SUCCESS";
export const JOB_POST_UPDATE_FAILURE = "JOB_POST_UPDATE_FAILURE";

export const JOB_POST_DELETE_REQUEST = "JOB_POST_DELETE_REQUEST";
export const JOB_POST_DELETE_SUCCESS = "JOB_POST_DELETE_SUCCESS";
export const JOB_POST_DELETE_FAILURE = "JOB_POST_DELETE_FAILURE";

export const JOB_POST_GET_REQUEST = "JOB_POST_GET_REQUEST";
export const JOB_POST_GET_SUCCESS = "JOB_POST_GET_SUCCESS";
export const JOB_POST_GET_FAILURE = "JOB_POST_GET_FAILURE";

export const JOB_POSTS_GET_REQUEST = "JOB_POSTS_GET_REQUEST";
export const JOB_POSTS_GET_SUCCESS = "JOB_POSTS_GET_SUCCESS";
export const JOB_POSTS_GET_FAILURE = "JOB_POSTS_GET_FAILURE";

export const JOB_POST_INTROS_GET_REQUEST = "JOB_POST_INTROS_GET_REQUEST";
export const JOB_POST_INTROS_GET_SUCCESS = "JOB_POST_INTROS_GET_SUCCESS";
export const JOB_POST_INTROS_GET_FAILURE = "JOB_POST_INTROS_GET_FAILURE";
