import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import AddIcon from "@material-ui/icons/Add";

import ButtonBase from "components/ButtonBase";

import { spaceSetRequestAction } from "containers/space/actions";
import { DASHBOARD } from "navigation/constants";

const useStyles = makeStyles({
  cardSpaces: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: 0,
    fontSize: 14,
    lineHeight: 20,
    fontWeight: 500,
  },
  cardButton: {
    alignItems: "center",
    backgroundColor: "transparent",
    border: "none",
    borderRadius: 8,
    boxSizing: "border-box",
    color: "inherit",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    flexGrow: 1,
    font: "inherit",
    justifyContent: "center",
    padding: "12px 20px",
    width: "100%",
    transition: "background-color .1s cubic-bezier(0.4, 0, 0.2, 1)",
  },
});

const elevation = 2;
const cardHeight = 208;
const cardWidth = 276;
const gutter = 1.5;

function Spaces(props) {
  const { spaces, spaceSetRequest } = props;
  const classes = useStyles();

  const handleOnSpace = useCallback(
    (space) => {
      spaceSetRequest(space, { redirect: DASHBOARD });
    },
    [spaceSetRequest]
  );

  return (
    <Box
      paddingTop={{ xs: 12, sm: 15, md: 15, lg: 18 }}
      color="white.main"
      minHeight="100vh"
    >
      <Box
        margin="0 auto"
        width={{
          sx: 574,
          sm: 600,
          md: 600,
          lg: 906,
          xl: 906,
        }}
      >
        <Box mb={3} pl={{ xs: 2, sm: gutter }}>
          <Typography gutterBottom variant="h6" color="inherit">
            Your spaces
          </Typography>
        </Box>
        <Box
          display="flex"
          flexWrap={{ xs: "nowrap", sm: "wrap" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Box
            width={{ xs: "100%", sm: cardWidth }}
            height={{ xs: 64, sm: cardHeight }}
            padding={{ xs: "0 12px", sm: 0 }}
            mx={{ xs: 0, sm: gutter }}
            mb={gutter * 2}
          >
            <Card className={classes.cardSpaces} elevation={elevation}>
              <ButtonBase
                className={classes.cardButton}
                component={Link}
                to={"/space/create"}
              >
                <AddIcon style={{ fontSize: 38 }} />
              </ButtonBase>
            </Card>
          </Box>
          {spaces?.map((space) => (
            <Box
              width={{ xs: "100%", sm: cardWidth }}
              height={{ xs: cardHeight }}
              padding={{ xs: "0 12px", sm: 0 }}
              mx={{ xs: 0, sm: gutter }}
              mb={gutter * 2}
              key={space.id.identifier}
            >
              <Card className={classes.cardSpaces} elevation={elevation}>
                <ButtonBase
                  className={classes.cardButton}
                  onClick={handleOnSpace}
                  item={space}
                >
                  {space.name}
                </ButtonBase>
              </Card>
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        bgcolor={"background.menu.0"}
        paddingTop={{ xs: 42, sm: 42, md: 42, lg: 45 }}
        position="absolute"
        top={0}
        left={0}
        right={0}
        zIndex={-1}
      />
    </Box>
  );
}

const mapStateToProps = (state) => ({
  spaces: state.space.spaces,
});

const actions = (dispatch) => ({
  spaceSetRequest: (payload, meta) =>
    dispatch(spaceSetRequestAction(payload, meta)),
});

export default connect(mapStateToProps, actions)(Spaces);
