import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuList from "@material-ui/core/MenuList";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";

import { spaceSetRequestAction } from "containers/space/actions";

import { SPACES, SPACE_CREATE } from "navigation/constants";

import MenuItem from "components/MenuItem";

const styles = (theme) => ({
  padding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
});

function SpacesMenu(props) {
  const { placeHolder, classes } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const spaces = useSelector((state) => state.space.spaces);
  const space = useSelector((state) => state.space.data);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnSelect = useCallback(
    (action) => {
      try {
        if (action["@type"] === "Space") {
          if (action.id.identifier === space.id.identifier) return;
          return dispatch(spaceSetRequestAction(action));
        }
        return;
      } catch (err) {
      } finally {
        handleClose();
      }
    },
    [dispatch, space.id.identifier]
  );

  return (
    <div>
      <Button
        aria-controls={placeHolder}
        aria-haspopup="true"
        onClick={handleOpen}
        endIcon={<ArrowDropDownIcon />}
        disableRipple
        disableElevation
      >
        {space?.name || placeHolder}
      </Button>
      <Menu
        id="company-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuList>
          <MenuItem disableRipple component={Link} to={SPACES}>
            See all spaces
          </MenuItem>
          <MenuItem disableRipple component={Link} to={SPACE_CREATE}>
            Add a space
          </MenuItem>
        </MenuList>
        <Divider light />
        <MenuList>
          <ListItemText className={classes.padding} secondary="All spaces" />
          {spaces.map((space) => {
            return (
              <MenuItem
                disableRipple
                item={space}
                key={space.id.identifier}
                onClick={handleOnSelect}
                selected={space.id.indentifier === space?.id.identifier}
              >
                {space.name}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </div>
  );
}

SpacesMenu.propTypes = {
  active: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
};

SpacesMenu.defaultProps = {
  placeHolder: "Select space",
  companies: [],
  active: null,
};

export default withStyles(styles)(SpacesMenu);
