import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { NavLink, Link } from "react-router-dom";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import TitleCollection from "./TitleCollection";
import AppBar from "@material-ui/core/AppBar";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import logo from "assets/img/logo-green.png";
import logoText from "assets/img/logo-text.png";

import OffsetHeader from "components/OffsetHeader";
import ManageMenu from "./ManageMenu";

const styles = (theme) => ({
  drawer: {
    paddingBottom: theme.spacing(2),
    width: theme.sizes.drawerWidth,
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolBar: {
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  offset: theme.mixins.toolbar,
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: "rgba(255, 255, 255, 0.7)",
    "& svg": {
      visibility: "hidden",
    },
    "@media(hover: hover) and (pointer: fine)": {
      "&:hover": {
        "& svg": {
          visibility: "visible",
        },
      },
    },
  },
  categoryButton: {
    flexDirection: "column",
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  categoryContainerActive: {
    backgroundColor: "rgba(71, 98, 130, 0.2)",
  },
  item: {
    paddingTop: 2,
    paddingBottom: 2,
    color: "rgba(255, 255, 255, 0.7)",
  },
  itemCategoryContainer: {
    paddingBottom: theme.spacing(2),
  },
  itemCategory: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  boxShadow: {
    boxShadow: "0 -1px 0 #404854 inset",
  },
  branding: {
    fontSize: 18,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: theme.palette.text.active,
  },
  itemPrimary: {
    fontSize: "inherit",
  },
  itemIcon: {
    color: "inherit",
    minWidth: "auto",
    marginRight: theme.spacing(2),
  },
  itemGlobalIconContainer: {
    color: "inherit",
    height: 38,
    width: 38,
    padding: 0,
    marginRight: theme.spacing(2),
  },
  itemGlobalIcon: {
    height: 32,
    width: 32,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  marginRight: {
    marginRight: theme.spacing(1.5),
  },
  largeFont: {
    fontSize: 16,
  },
});

function Navigator(props) {
  const { classes, menu, onNavigate = () => {}, ...other } = props;
  const [categoryOpen, setCategoryOpen] = useState([]);

  useEffect(() => {
    const { categories } = menu;
    let categoryArray = [];
    for (let i = 0; i < categories.data.length; i++) {
      if (categories.data[i].defaultOpen) {
        categoryArray.push(categories.data[i].id);
      }
    }
    setCategoryOpen(categoryArray);
  }, [menu]);

  const handleCategoryToggle = useCallback(
    (id) => {
      const index = categoryOpen.indexOf(id);
      if (index > -1) {
        let categoryArray = [...categoryOpen];
        categoryArray.splice(index, 1);
        setCategoryOpen(categoryArray);
      } else {
        setCategoryOpen([...categoryOpen, id]);
      }
    },
    [categoryOpen]
  );

  const handleOnNavigate = useCallback(
    (e) => {
      onNavigate();
      // e.preventDefault();
    },
    [onNavigate]
  );

  return (
    <Drawer variant="permanent" classes={{ paper: classes.drawer }} {...other}>
      <AppBar
        className={classes.appBar}
        position="absolute"
        color="transparent"
        elevation={0}
      >
        <ButtonBase
          disableRipple
          component={Link}
          to={menu.branding.route}
          className={clsx(classes.offset, classes.toolBar)}
          onClick={handleOnNavigate}
        >
          <img
            className={classes.marginRight}
            src={logo}
            height="24"
            width="auto"
            alt={menu.branding.title}
          />
          <img
            src={logoText}
            height="14"
            width="auto"
            alt={menu.branding.title}
          />
        </ButtonBase>
      </AppBar>
      <OffsetHeader />
      <List disablePadding style={{ flexGrow: 1 }}>
        <ListItem
          button
          disableRipple
          component={NavLink}
          to={menu.home.route}
          onClick={handleOnNavigate}
          activeClassName={classes.itemActiveItem}
          className={clsx(
            classes.item,
            classes.itemCategory,
            classes.boxShadow
          )}
        >
          <ListItemIcon className={classes.itemIcon}>
            {menu.home.icon}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            {menu.home.title}
          </ListItemText>
        </ListItem>
        {menu.categories.data.map((category) => {
          const open = categoryOpen.includes(category.id);
          return (
            <div
              className={open ? classes.categoryContainerActive : ""}
              key={category.id}
            >
              <ListItem
                button
                disableRipple
                id={category.id}
                onClick={() => handleCategoryToggle(category.id)}
                classes={{
                  root: classes.categoryHeader,
                  button: classes.categoryButton,
                }}
              >
                <div className={classes.row}>
                  <ListItemText
                    classes={{
                      primary: classes.categoryHeaderPrimary,
                    }}
                  >
                    {category.title}
                  </ListItemText>
                  {open ? <ExpandLess /> : <ExpandMore />}
                </div>
                {!open && <TitleCollection items={category.data} />}
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <div className={classes.itemCategoryContainer}>
                  {category.data.map((item) => (
                    <ListItem
                      key={item.id}
                      button
                      disableRipple
                      component={NavLink}
                      to={item.route}
                      onClick={handleOnNavigate}
                      activeClassName={classes.itemActiveItem}
                      className={clsx(classes.item)}
                    >
                      <ListItemIcon className={classes.itemIcon}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.title}
                        classes={{
                          primary: classes.itemPrimary,
                        }}
                      />
                    </ListItem>
                  ))}
                </div>
              </Collapse>
              <Divider />
            </div>
          );
        })}
      </List>
      <List disablePadding style={{ flexShrink: 0 }}>
        {menu.global.data.map((g) => (
          <ListItem
            key={g.id}
            button
            disableRipple
            component={NavLink}
            to={g.route}
            onClick={handleOnNavigate}
            activeClassName={classes.itemActiveItem}
            className={clsx(classes.item, classes.itemCategory)}
          >
            <IconButton className={classes.itemGlobalIconContainer}>
              {g.icon}
            </IconButton>
            <ListItemText
              classes={{
                primary: clsx(classes.itemPrimary),
              }}
            >
              {g.title}
            </ListItemText>
          </ListItem>
        ))}
        <ManageMenu
          listItem
          title={menu.manage.title}
          listItemProps={{
            className: clsx(classes.item, classes.itemCategory),
          }}
          listItemTextProps={{
            classes: {
              primary: clsx(classes.itemPrimary),
            },
          }}
          anchorOrigin={{ vertical: "center", horizontal: "center" }}
          transformOrigin={{ vertical: "bottom", horizontal: "left" }}
        />
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);
