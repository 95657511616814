import React, { useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import OffsetHeader from "components/OffsetHeader";

import Box from "@material-ui/core/Box";
import ScreenLoader from "components/ScreenLoader";
import ButtonBase from "components/ButtonBase";

import // jobPostsGetRequestAction,
// jobPostGetRequestAction,
// jobPostIntrosGetRequestAction,
"containers/jobPost/actions";

function JobPost(props) {
  const { id } = useParams();
  // const mountedRef = useRef(false);
  // const dispatch = useDispatch();
  const jobPostLoading = useSelector((state) => state.jobPost.loading);
  const jobPost = useSelector((state) => state.jobPost.jobPost);
  const introductions = useSelector((state) => state.jobPost.introductions);

  const handleOnIntroduction = useCallback((intro) => {
    console.log(intro);
  }, []);

  if (jobPostLoading) {
    return <ScreenLoader />;
  }

  return (
    <Box px={2}>
      <OffsetHeader />
      {jobPost[id] && (
        <Box>
          <h1>{jobPost[id].title}</h1>
          <small>{jobPost[id].id}</small>
          <pre>{JSON.stringify(jobPost[id], null, 2)}</pre>
          {introductions && introductions[id] && (
            <Box>
              <h2>Introductions</h2>
              {introductions[id].map((introduction) => (
                <Box key={introduction.id} my={2}>
                  <ButtonBase
                    onClick={handleOnIntroduction}
                    item={introduction}
                  >
                    <pre style={{ textAlign: "left" }}>
                      {JSON.stringify(introduction, null, 2)}
                    </pre>
                  </ButtonBase>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default JobPost;
