import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";

import SpacesMenu from "./SpacesMenu";

const styles = (theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    color: theme.palette.text.primary,
  },
  button: {
    borderColor: theme.palette.text.primary,
  },
  toolbar: {
    color: theme.palette.text.primary,
  },
});

function Header(props) {
  const { classes, onDrawerToggle, mainContentRef } = props;
  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 16,
    target: mainContentRef,
  });

  return (
    <React.Fragment>
      <AppBar
        color={scrollTrigger ? "default" : "transparent"}
        position="absolute"
        elevation={scrollTrigger ? 2 : 0}
      >
        <Toolbar className={classes.toolbar}>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <SpacesMenu />
            <Grid item xs />
            {/* <Grid item>
              <Tooltip title="Alerts • No alerts">
                <IconButton color="inherit">
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <ProfileMenu />
            </Grid> */}
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

const actions = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, actions),
  withStyles(styles)
)(Header);
