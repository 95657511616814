import { createTheme } from "@material-ui/core/styles";
import shared from "theme/shared";

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
    text: {
      primary: "#323338",
      active: "#4fc3f7",
    },
    background: {
      paper: "#fff",
      default: "#f6fff6",
      overlay: {
        dark: "rgba(0, 0, 0, 0.72)",
        light: "rgba(255, 255, 255, 0.92)",
      },
      menu: {
        0: "#18202c",
      },
    },
    divider: "#404854",
    branding: {
      green: "#7AD97C",
    },
    white: {
      main: "#fff",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  action: {
    selected: "rgba(0,0,0,0.04)",
    hover: "rgba(0,0,0,0.06)",
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  ...shared,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: theme.palette.background.menu[0],
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        margin: "0 16px",
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up("md")]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: theme.palette.divider,
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItem: {
      button: {
        "@media(hover: hover) and (pointer: fine)": {
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.08)",
          },
        },
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    MuiLink: {
      root: {
        "@media(hover: hover) and (pointer: fine)": {
          "&:hover": {
            opacity: 0.72,
          },
        },
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: theme.palette.background.default,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary,
        "@media(hover: hover) and (pointer: fine)": {
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
            opacity: 1,
          },
        },
      },
      selected: {
        color: theme.palette.text.active,
        backgroundColor: `${theme.palette.action.hover}!important`,
      },
    },
  },
};

export default theme;
