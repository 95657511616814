import { all } from "redux-saga/effects";

// sagas
import watchAppActions from "containers/app/sagas";
import watchAuthActions from "containers/auth/sagas";
import watchUserActions from "containers/user/sagas";
import watchSpaceActions from "containers/space/sagas";
import watchJobPostActions from "containers/jobPost/sagas";

export default function* root() {
  yield all([
    watchAppActions(),
    watchAuthActions(),
    watchUserActions(),
    watchSpaceActions(),
    watchJobPostActions(),
  ]);
}
