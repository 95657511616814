import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
  height: { height: ({ size }) => size },
}));

function OffsetHeader(props) {
  const { size } = props;
  const classes = useStyles(props);
  return <div className={clsx(classes.offset, size && classes.height)} />;
}

export default OffsetHeader;
