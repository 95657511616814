import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import MuiLink from "@material-ui/core/Link";

import { authSignOutRequestAction } from "containers/auth/actions";

import { PRIVACY_POLICY, TERMS_OF_SERVICE } from "navigation/constants";

const avatarSize = {
  large: 38,
  xxlarge: 84,
};

const styles = (theme) => ({
  menu: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: 300,
    textAlign: "center",
  },
  padding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  avatarListItem: {
    width: avatarSize.large,
    height: avatarSize.large,
    marginRight: theme.spacing(2),
  },
  avatarXXLarge: {
    width: avatarSize.xxlarge,
    height: avatarSize.xxlarge,
    margin: "0 auto",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

function ManageMenu(props) {
  const {
    classes,
    user,
    profile,
    title,
    anchorOrigin,
    transformOrigin,
    listItem,
    listItemProps,
    listItemTextProps,
    authSignOutRequest,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnSelect = useCallback(
    async (action) => {
      try {
        if (action?.type === "action") {
          if (action?.event === "signOut") {
            return authSignOutRequest();
          }
        }
      } catch (err) {
      } finally {
        handleClose();
      }
    },
    [authSignOutRequest]
  );

  return (
    <>
      {listItem ? (
        <ListItem {...listItemProps} button disableRipple onClick={handleOpen}>
          <Avatar
            src={profile.photoUrl}
            alt="Profile photo"
            className={classes.avatarListItem}
          />
          <ListItemText {...listItemTextProps}>{title}</ListItemText>
        </ListItem>
      ) : (
        <IconButton
          color="inherit"
          className={classes.iconButtonAvatar}
          onClick={handleOpen}
        >
          <Avatar src={profile.photoUrl} alt="Profile photo" />
        </IconButton>
      )}
      <Menu
        id="profile-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        MenuListProps={{
          className: classes.menu,
        }}
      >
        <Avatar
          src={profile.photoUrl}
          alt="Profile photo"
          className={classes.avatarXXLarge}
        />
        <ListItemText
          primary={profile?.displayName}
          secondary={user?.phoneNumber}
        />
        <Divider light className={classes.divider} />
        <Button
          color="primary"
          variant="outlined"
          onClick={() => handleOnSelect({ type: "action", event: "signOut" })}
        >
          Sign out
        </Button>
        <Divider light className={classes.divider} />
        <MuiLink component={Link} to={PRIVACY_POLICY}>
          Privacy Policy
        </MuiLink>
        {" • "}
        <MuiLink component={Link} to={TERMS_OF_SERVICE}>
          Terms of Service
        </MuiLink>
      </Menu>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.data,
  profile: state.user.data.profiles[0] || {},
});

const actions = (dispatch) => ({
  authSignOutRequest: () => dispatch(authSignOutRequestAction()),
});

ManageMenu.propTypes = {
  active: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
};

ManageMenu.defaultProps = {
  placeHolder: "Select company",
  companies: [],
  active: null,
  /**
   *
   * Position menu
   * Anchor playground https://material-ui.com/components/popover/
   *
   */
  transformOrigin: { vertical: "bottom", horizontal: "center" },
  anchorOrigin: { vertical: "top", horizontal: "center" },
  listItem: false,
};

export default compose(
  connect(mapStateToProps, actions),
  withStyles(styles)
)(ManageMenu);
