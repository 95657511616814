import React from "react";
import { connect } from "react-redux";
import ScreenLoader from "components/ScreenLoader";

import OffsetHeader from "components/OffsetHeader";

function Dashboard(props) {
  const { loading } = props;

  if (loading) {
    return <ScreenLoader loadingDelay={900} />;
  }

  return (
    <div>
      <OffsetHeader />
      <h1>Dashboard</h1>
      {/* {Array.apply(null, Array(20)).map((t, i) => (
        <h1 key={i}>test</h1>
      ))} */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: false,
});

const actions = (dispatch) => ({});

export default connect(mapStateToProps, actions)(Dashboard);
