import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import OffsetHeader from "components/OffsetHeader";
import Button from "@material-ui/core/Button";

import Spinner from "components/Spinner";

import { hcn } from "services/hcn";

import { Form, Field } from "react-final-form";

function SpaceCreate(props) {
  const { user } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const onSubmit = useCallback(
    async ({ displayName }) => {
      try {
        setLoading(true);
        const id = user.profiles[0].id;
        const space = await hcn.doCreateBusiness({
          id,
          input: {
            business: { displayName },
            location: {
              displayName: "placeName",
              country: "United Kingdom",
              city: "Milton Keynes",
            },
          },
        });
        console.log("SPACE_CREATED", space);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [user.profiles]
  );

  return (
    <div>
      <OffsetHeader size={64} />
      <Button onClick={() => history.goBack()}>Go back</Button>
      <h1>SpaceCreate</h1>
      <Form
        onSubmit={onSubmit}
        // validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="displayName"
              component="input"
              placeholder="Name your space"
            />
            <button type="submit">Submit</button>
          </form>
        )}
      />
      {loading && <Spinner />}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.data,
});

const actions = (dispatch) => ({});

export default connect(mapStateToProps, actions)(SpaceCreate);
