import React, { useCallback, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import OffsetHeader from "components/OffsetHeader";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import { JOB_POSTS } from "navigation/constants";

import {
  jobPostsGetRequestAction,
  jobPostGetRequestAction,
  jobPostIntrosGetRequestAction,
} from "containers/jobPost/actions";

function JobPosts(props) {
  const mountedRef = useRef(false);
  const dispatch = useDispatch();
  const [activeJobPost, setActiveJobPost] = useState(null);
  const space = useSelector((state) => state.space.data);
  const jobPosts = useSelector((state) => state.jobPost.jobPosts);
  const jobPost = useSelector((state) => state.jobPost.jobPost);
  const introductions = useSelector((state) => state.jobPost.introductions);

  useEffect(() => {
    dispatch(jobPostsGetRequestAction());
  }, [dispatch, space.id?.identifier]);

  useEffect(() => {
    if (!mountedRef.current) {
      return (mountedRef.current = true);
    }
    dispatch(jobPostGetRequestAction({ jobPostId: activeJobPost.id }));
    dispatch(jobPostIntrosGetRequestAction({ jobPostId: activeJobPost.id }));
  }, [dispatch, activeJobPost?.id]);

  const handleOnJobPost = useCallback(
    (jp) => {
      dispatch(jobPostGetRequestAction({ jobPostId: jp.id }));
      dispatch(jobPostIntrosGetRequestAction({ jobPostId: jp.id }));
      dispatch(push(`${JOB_POSTS}/${jp.id}`));
    },
    [dispatch]
  );

  return (
    <Box px={2}>
      <OffsetHeader />
      <h1>JobPosts</h1>
      {jobPosts.map((jp) => (
        <Box mb={2} key={jp.id} onClick={() => handleOnJobPost(jp)}>
          <Button>{jp.title}</Button>
        </Box>
      ))}
      {activeJobPost && jobPost && jobPost[activeJobPost.id] && (
        <Box>
          <h1>{jobPost[activeJobPost.id].title}</h1>
          <small>{jobPost[activeJobPost.id].id}</small>
          <pre>{JSON.stringify(jobPost[activeJobPost.id], null, 2)}</pre>
          {introductions && introductions[activeJobPost.id] && (
            <Box>
              <h2>Introductions</h2>
              {introductions[activeJobPost.id].map((introduction) => (
                <Box key={introduction.id} my={2}>
                  <pre>{JSON.stringify(introduction, null, 2)}</pre>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default JobPosts;
