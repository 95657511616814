import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import OffsetHeader from "components/OffsetHeader";

import logo from "assets/img/logo-green.png";
import logoText from "assets/img/logo-text.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  main: {
    position: "relative",
    overflow: "auto",
    background: theme.palette.background.primary,
    height: "100vh",
    width: "100%",
    minWidth: 375,
  },
  marginRight: {
    marginRight: theme.spacing(1.5),
  },
  backgroundColor: {
    backgroundColor: theme.palette.background.menu[0],
  },
}));

function Simple(props) {
  const { children, initialized } = props;
  const classes = useStyles();

  if (!initialized) {
    return null;
  }

  return (
    <div className={classes.root}>
      <AppBar
        className={classes.backgroundColor}
        position="absolute"
        elevation={0}
      >
        <Box py={1}>
          <Toolbar>
            <img
              className={classes.marginRight}
              src={logo}
              height="28"
              width="auto"
              alt="Human Cloud Logo"
            />
            <img src={logoText} height="16" width="auto" alt="Human Cloud" />
          </Toolbar>
        </Box>
      </AppBar>
      <OffsetHeader />
      <main className={classes.main}>{children}</main>
    </div>
  );
}

const mapStateToProps = (state) => ({
  initialized: state.app.initialized,
});

export default connect(mapStateToProps)(Simple);
