import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

// constants
import { APP_RESET_STATE } from "containers/app/constants";

// reducers
import appReducer from "containers/app/reducer";
import authReducer from "containers/auth/reducer";
import userReducer from "containers/user/reducer";
import spaceReducer from "containers/space/reducer";
import jobPostReducer from "containers/jobPost/reducer";

const rootReducer = (history) => {
  const combineReducer = combineReducers({
    router: connectRouter(history),
    app: appReducer,
    auth: authReducer,
    user: userReducer,
    space: spaceReducer,
    jobPost: jobPostReducer,
  });

  return (state, action) => {
    let $state = state;
    if (action.type === APP_RESET_STATE) {
      /**
       * resets reducer state, ref – https://stackoverflow.com/a/35641992
       *
       * keep passed state (action.payload) or reset completely,
       * undefined will reset everything
       */
      $state = action.payload ? action.payload : undefined;
    }

    return combineReducer($state, action);
  };
};

export default rootReducer;
