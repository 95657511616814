import * as c from "./constants";

const initialState = {
  data: null,
  loading: false,
  spaces: [],
};

const spaceReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case c.SPACE_SET_SUCCESS:
    case c.SPACE_CREATE_SUCCESS:
      return {
        ...state,
        data: payload,
      };
    case c.SPACES_GET_SUCCESS:
      return {
        ...state,
        spaces: payload.spaces,
      };
    default:
      return state;
  }
};

export default spaceReducer;
