import React, { Component } from "react";
import { connect } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";

import Spinner from "components/Spinner";

import { fb } from "services/firebase";
import RootNavigation from "navigation/rootNavigation";

import { authStatusSetAction } from "containers/auth/actions";

import { light } from "theme";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialized: false,
    };
  }
  componentDidMount() {
    this.authListener = fb.auth().onAuthStateChanged(this.onAuthStateChange);
  }

  componentWillUnmount() {
    this.authListener();
  }

  onAuthStateChange = (user) => {
    const { authStatusSet } = this.props;
    authStatusSet(Boolean(user));
    this.setState({ initialized: true });
  };

  render() {
    const { initialized } = this.state;
    return (
      <ThemeProvider theme={light}>
        <CssBaseline />
        {initialized ? (
          <RootNavigation />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <Spinner loadingDelay={900} />
          </Box>
        )}
      </ThemeProvider>
    );
  }
}

const actions = (dispath) => ({
  authStatusSet: (status) => dispath(authStatusSetAction(status)),
});

export default connect(null, actions)(App);
