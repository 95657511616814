import * as c from "./constants";

import { SPACE_CLEANUP } from "containers/space/constants";

const initialState = {
  loading: false,
  jobPosts: [],
  jobPost: null,
  err: "",
};

const jobPostReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case SPACE_CLEANUP:
      return {
        ...initialState,
      };
    case c.JOB_POST_CREATE_REQUEST:
    case c.JOB_POST_UPDATE_REQUEST:
    case c.JOB_POST_DELETE_REQUEST:
    case c.JOB_POST_GET_REQUEST:
    case c.JOB_POSTS_GET_REQUEST:
    case c.JOB_POST_INTROS_GET_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case c.JOB_POST_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        jobPosts: [...state.jobPosts, payload],
        jobPost: {
          ...state.jobPost,
          [payload.id]: payload,
        },
        err: "",
      };

    case c.JOB_POST_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        jobPost: {
          ...state.jobPost,
          [payload.id]: payload,
        },
        err: "",
      };

    case c.JOB_POST_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        // jobPosts: TODO: update elem in array with properties for list
        jobPost: {
          ...state.jobPost,
          [payload.id]: payload,
        },
        err: "",
      };

    case c.JOB_POST_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        jobPosts: state.jobPosts.filter((item) => item.id !== payload.id),
        jobPost: {
          ...state.jobPost,
          [payload.id]: undefined,
        },
        err: "",
      };

    case c.JOB_POSTS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        jobPosts: payload.jobPosts,
      };

    case c.JOB_POST_INTROS_GET_SUCCESS:
      return {
        ...state,
        introductions: {
          ...state.introductions,
          [payload.jobPostId]: payload.introductions,
        },
      };

    case c.JOB_POST_CREATE_FAILURE:
    case c.JOB_POST_UPDATE_FAILURE:
    case c.JOB_POST_DELETE_FAILURE:
    case c.JOB_POST_GET_FAILURE:
    case c.JOB_POSTS_GET_FAILURE:
    case c.JOB_POST_INTROS_GET_FAILURE:
      return {
        ...state,
        loading: false,
        err: payload.masseage,
      };

    default:
      return state;
  }
};

export default jobPostReducer;
