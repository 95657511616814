import * as c from "./constants";

// CREATE
export const jobPostCreateRequestAction = ({ businessId, input }) => ({
  type: c.JOB_POST_CREATE_REQUEST,
  payload: {
    businessId,
    input,
  },
  error: false,
});

export const jobPostCreateSuccessAction = (jobPost) => ({
  type: c.JOB_POST_CREATE_SUCCESS,
  payload: {
    ...jobPost,
  },
  error: false,
});

export const jobPostCreateFailureAction = (message) => ({
  type: c.JOB_POST_CREATE_FAILURE,
  payload: { message },
  error: true,
});

// UPDATE
export const jobPostUpdateRequestAction = ({
  businessId,
  jobPostId,
  input,
}) => ({
  type: c.JOB_POST_UPDATE_REQUEST,
  payload: {
    businessId,
    jobPostId,
    input,
  },
  error: false,
});

export const jobPostUpdateSuccessAction = (jobPost) => ({
  type: c.JOB_POST_UPDATE_SUCCESS,
  payload: { ...jobPost },
  error: false,
});

export const jobPostUpdateFailureAction = (message) => ({
  type: c.JOB_POST_UPDATE_FAILURE,
  payload: { message },
  error: true,
});

// DELETE
export const jobPostDeleteRequestAction = ({ businessId, jobPostId }) => ({
  type: c.JOB_POST_DELETE_REQUEST,
  payload: { businessId, jobPostId },
  error: false,
});

export const jobPostDeleteSuccessAction = (id) => ({
  type: c.JOB_POST_DELETE_SUCCESS,
  payload: { id },
  error: false,
});

export const jobPostDeleteFailureAction = (message) => ({
  type: c.JOB_POST_DELETE_FAILURE,
  payload: { message },
  error: true,
});

// GET POST
export const jobPostGetRequestAction = ({ jobPostId }) => ({
  type: c.JOB_POST_GET_REQUEST,
  payload: { jobPostId },
  error: false,
});

export const jobPostGetSuccessAction = (jobPost) => ({
  type: c.JOB_POST_GET_SUCCESS,
  payload: { ...jobPost },
  error: false,
});

export const jobPostGetFailureAction = (message) => ({
  type: c.JOB_POST_GET_FAILURE,
  payload: { message },
  error: true,
});

// GET POSTS
export const jobPostsGetRequestAction = () => ({
  type: c.JOB_POSTS_GET_REQUEST,
  payload: null,
  error: false,
});

export const jobPostsGetSuccessAction = (jobPosts) => ({
  type: c.JOB_POSTS_GET_SUCCESS,
  payload: {
    jobPosts,
  },
  error: false,
});

export const jobPostsGetFailureAction = (message) => ({
  type: c.JOB_POSTS_GET_FAILURE,
  payload: { message },
  error: true,
});

// INTROS GET
export const jobPostIntrosGetRequestAction = ({ businessId, jobPostId }) => ({
  type: c.JOB_POST_INTROS_GET_REQUEST,
  payload: { businessId, jobPostId },
  error: false,
});

export const jobPostIntrosGetSuccessAction = ({
  introductions,
  jobPostId,
}) => ({
  type: c.JOB_POST_INTROS_GET_SUCCESS,
  payload: {
    introductions,
    jobPostId,
  },
  error: false,
});

export const jobPostIntrosGetFailureAction = (message) => ({
  type: c.JOB_POST_INTROS_GET_FAILURE,
  payload: { message },
  error: true,
});
