import React, { useCallback, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import ButtonBase from "components/ButtonBase";

const useStyles = makeStyles({
  btn: {
    cursor: "default",
  },
});

const initCLicks = 5;

function Instructions(props) {
  const { onChangeAuth, qrAuth } = props;
  const classes = useStyles();
  const [clickCount, setClickCount] = useState(initCLicks);

  const handleCount = useCallback(() => {
    setClickCount(clickCount - 1);
  }, [clickCount]);

  useEffect(() => {
    if (clickCount <= 0) {
      setClickCount(initCLicks);
      onChangeAuth(!qrAuth);
    }
  }, [clickCount, onChangeAuth, qrAuth]);

  return (
    <ButtonBase onClick={handleCount} disableRipple className={classes.btn}>
      {qrAuth ? (
        <Box textAlign="left">
          <Box mb={{ xs: 2, sm: 3 }}>
            <Typography variant="h5">To use Human Cloud</Typography>
          </Box>
          <Box mb={{ xs: 1, sm: 2 }}>
            <Typography variant="body1">
              1. Open Amooto on your phone
            </Typography>
          </Box>
          <Box mb={{ xs: 1, sm: 2 }}>
            <Typography variant="body1">2. Tab |me| and select Scan</Typography>
          </Box>
          <Box mb={{ xs: 1, sm: 2 }}>
            <Typography variant="body1">
              3. Point your phone to this screen to capture the code
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1">
              4. Confirm security code received by text message
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box textAlign="left">
          <Box mb={{ xs: 2, sm: 3 }}>
            <Typography variant="h5">To use Human Cloud</Typography>
          </Box>
          <Box mb={{ xs: 1, sm: 2 }}>
            <Typography variant="body1">1. Enter your mobile number</Typography>
          </Box>
          <Box mb={{ xs: 1, sm: 2 }}>
            <Typography variant="body1">
              2. Enter the 6-digit code sent to your mobile phone
            </Typography>
          </Box>
        </Box>
      )}
    </ButtonBase>
  );
}

export default Instructions;
