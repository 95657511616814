import { takeLatest, put, all, call } from "redux-saga/effects";
import { push } from "connected-react-router";

import { hcn } from "services/hcn";

import * as c from "./constants";
import {
  // spaceCreateFailureAction,
  // spaceCreateSuccesAction,
  spaceSetFailureAction,
  spaceSetSuccesAction,
  spacesGetFailureAction,
  spacesGetSuccessAction,
  spaceCleanupAction,
} from "./actions";

function* spacesGet(uid) {
  const {
    spaces: {
      businesses,
      // teams,
    },
  } = yield call(hcn.doGetSpaces, uid);
  let spaces = [];
  if (businesses) {
    for (let i = 0; i < businesses.length; i++) {
      spaces.push({
        "@context": "https://schema.org",
        "@type": "Space",
        id: {
          "@type": "Organization",
          identifier: businesses[i].id,
        },
        name: businesses[i].displayName,
      });
    }
  }

  return spaces;
}

export function* spacesGetRequest(action) {
  const { rethrow } = action.settings;
  try {
    const authUser = yield call(hcn.goGetAuthUser);
    const spaces = yield call(spacesGet, authUser.uid);
    yield put(spacesGetSuccessAction(spaces));
    return spaces;
  } catch (err) {
    if (err.message) {
      yield put(spacesGetFailureAction(err.message));
    }
    if (rethrow) {
      throw err;
    }
  }
}

function* spaceSetRequest(action) {
  try {
    const { payload, meta } = action;
    yield put(spaceCleanupAction());
    yield put(spaceSetSuccesAction(payload));
    if (meta.redirect) {
      yield put(push(meta.redirect));
    }
  } catch (err) {
    yield put(spaceSetFailureAction(err.message));
  }
}

export default function* watchAuth() {
  yield all([
    takeLatest(c.SPACE_SET_REQUEST, spaceSetRequest),
    takeLatest(c.SPACES_GET_REQUEST, spacesGetRequest),
  ]);
}
