import React, { Component } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";

import * as ROUTE from "./constants";

import AppNavigation from "./appNavigation";
import Login from "screens/Login";

class RootNavigation extends Component {
  render() {
    const { isAuthenticated } = this.props;

    if (!isAuthenticated) {
      return (
        <Switch>
          <Route exact path={ROUTE.ROOT}>
            <Login />
          </Route>
          <Redirect to={ROUTE.ROOT} />
        </Switch>
      );
    }

    return <AppNavigation />;
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.authenticated,
});

export default connect(mapStateToProps)(RootNavigation);
