/* eslint-disable curly */
import { GraphQLClient, gql } from "graphql-request";
// import { doGetAuthUser, doGetAuthUserToken } from "services/firebase";
import { fb } from "services/firebase";

import * as q from "./query";
import * as m from "./mutation";

const endpoint = process.env.REACT_APP_GRAPHQL_URI;

// TODO: remove bypass before production
const bypass = false;

function getAuthUser() {
  return new Promise((res, rej) => {
    try {
      const user = fb.auth().currentUser;
      res(user);
    } catch (err) {
      console.log(err);
      rej(err);
    }
  });
}

function getAuthUserToken() {
  return new Promise(async (res, rej) => {
    try {
      const token = await fb.auth().currentUser?.getIdToken();
      res(token);
    } catch (err) {
      console.log(err);
      rej(err);
    }
  });
}

class HcnApi {
  constructor() {
    this.client = new GraphQLClient(endpoint);
  }

  /**
   *
   * @param {*} query
   * @param {*} variables
   *
   * Requester
   *
   */
  request = (query, variables) => {
    if (bypass) {
      return getAuthUser()
        .then((authUser) =>
          this.client.setHeader("authorization", `uid ${authUser.uid}`)
        )
        .then(() => this.client.request(query, variables));
    } else {
      return getAuthUserToken()
        .then((token) => {
          if (token) {
            return this.client.setHeader("authorization", `Bearer ${token}`);
          }
          return undefined;
        })
        .then(() => this.client.request(query, variables));
    }
  };

  // TODO: refactor to use getCurrentUser instaed of login
  doGetSpaces = async (uid) => await this.request(m.SPACES, { uid });

  goGetAuthUser = async () => await getAuthUser();

  // TODO: prepare api to take authUser
  doCreateUser = ({ uid, input }) =>
    this.request(m.CREATE_USER, { uid, input });

  // TODO: prepare api to take authUser
  doLoginUser = (uid) => {
    return this.request(m.LOGIN_USER, { uid });
  };

  /**
   * Get current user
   */
  doGetCurrentUser = () => this.request(q.GET_CURRENT_USER);

  /**
   * Get personalized questMap
   */
  doGetQuestMap = async () => {
    const user = await getAuthUser();
    return this.request(q.QUEST_MAP_GET, { uid: user.uid });
  };

  /**
   *
   * @param {String} questId
   */
  doGetChallengeSession = (id, taskIds) =>
    this.request(q.GET_CHALLENGE_SESSION, { id, taskIds });

  /**
   *
   * @param {Object} result
   */
  doSubmitChallengeSession = async (result) => {
    const user = await getAuthUser();
    const { data, done, ...rest } = result; // remove keys from result
    return this.request(m.SUBMIT_CHALLENGE_SESSION, {
      uid: user.uid,
      input: rest,
    });
  };

  doGetScoreByQuestId = (scaleId) =>
    this.request(q.GET_IP_SCORE_BY_SCALE, { scaleId });

  doGetWallet = async () => {
    const user = await getAuthUser();
    return this.request(q.GET_WALLET, { uid: user.uid });
  };

  doGetWorkMatch = () => this.request(q.GET_HOME_TIMELINE);

  doClaimFundingReward = (matchId) => this.request(m.CLAIM_REWARD, matchId);

  doGetLoginChannel = () => this.request(m.GET_LOGIN_CHANNEL);

  doUpdateLoginChannelStatus = ({ id, input }) =>
    this.request(m.SET_LOGIN_CHANNEL_STATUS, { id, input });

  doCreateBusiness = ({ id, input }) =>
    this.request(m.CREATE_BUSINESS, { id, input });

  // JobPost
  /**
   * Create JobPost
   * @param {String} businessId
   * @param {Object} input
   */
  doCreateJobPost = async ({ businessId: id, input }) =>
    await this.request(m.JOB_POST_CREATE, { id, input });

  /**
   * Update Job Post
   * @param {String} businessId
   * @param {String} jobPostId
   * @param {Object} input
   */
  doUpdateJobPost = async ({ businessId: id, jobPostId, input }) =>
    await this.request(m.JOB_POST_UPDATE, { id, jobPostId, input });

  /**
   * Delete Job Post
   * @param {String} businessId
   * @param {String} jobPostId
   */
  doDeleteJobPost = async ({ businessId: id, jobPostId }) =>
    await this.request(m.JOB_POST_UPDATE, { id, jobPostId });

  /**
   * Get Job Post by id
   * @param {String} jobPostId
   */
  doGetJobPostById = async ({ jobPostId: id }) =>
    await this.request(q.JOB_POST_GET, { id });

  /**
   * Get Job Posts for business
   * @param {String} businessId
   * @param {Int} limit
   * @param {Object} sort e.g. { order: "ASC", byKey: "createdAt" }
   */
  doGetJobPostsByBusinessId = async ({
    businessId,
    limit = 100,
    sort = { order: "ASC", byKey: "createdAt" },
  }) =>
    await this.request(q.JOB_POSTS_GET, {
      limit,
      sort,
      filter: { publisherId: businessId },
    });

  /**
   * Get Job Post Introductions (applications)
   * @param {String} businessId
   * @param {String} jobPostId
   */
  doGetJobPostIntroductions = async ({ businessId: id, jobPostId }) =>
    await this.request(q.JOB_POST_INTROS_GET, { id, jobPostId });
}

let HcnApiInstance;

const hcn = (function () {
  if (HcnApiInstance) return HcnApiInstance;
  HcnApiInstance = new HcnApi();
  return HcnApiInstance;
})();

export { hcn, gql };
