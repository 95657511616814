import { gql } from "graphql-request";

export const GET_LOGIN_CHANNEL = gql`
  mutation channelGet {
    channel: UserLoginChannelGet {
      id
      createdAt
      url
    }
  }
`;

export const SET_LOGIN_CHANNEL_STATUS = gql`
  mutation channelStatus($id: ID!, $input: loginChannelStatus!) {
    UserLoginChannelStatus(id: $id, input: $input) {
      id
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUserMutation($uid: ID!, $input: userInput) {
    newUser: UserSignup(uid: $uid, input: $input) {
      uid
      updatedAt
      createdAt
      lastLoginAt
      email
      phoneNumber
    }
  }
`;

export const LOGIN_USER = gql`
  mutation login($uid: ID!) {
    user: UserLogin(uid: $uid) {
      uid
      createdAt
      updatedAt
      lastLoginAt
      phoneNumber
      email
      teams {
        id
        displayName
        business {
          id
          displayName
        }
      }
      locations {
        id
        displayName
        country
        city
      }
      profiles {
        id
        displayName
        photoUrl
        createdAt
        updatedAt
      }
      businesses {
        id
        displayName
      }
    }
  }
`;

// TODO: rewrite for actual spaces
export const SPACES = gql`
  mutation login($uid: ID!) {
    spaces: UserLogin(uid: $uid) {
      teams {
        id
        displayName
        business {
          id
          displayName
        }
      }
      businesses {
        id
        displayName
      }
    }
  }
`;

export const UPDATE_BUSINESS_AVATAR = gql`
  mutation updateBusinessAvatar($xid: ID!, $photoURL: String!) {
    updateAvater: UpdateBusiness(xid: $xid, photoURL: $photoURL) {
      xid
      photoURL
    }
  }
`;

export const DELETE_BUSINESS = gql`
  mutation deleteBusinessMutation($xid: ID!) {
    DeleteBusiness(xid: $xid) {
      xid
    }
  }
`;

export const UPDATE_USER_AVATAR = gql`
  mutation updateUserAvatar($xid: ID!, $photoURL: String!) {
    updateUser: UpdateUser(uid: $xid, photoURL: $photoURL) {
      uid
      photoURL
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfileMutation(
    $uid: ID!
    $displayName: String
    $email: String
    $locale: String
    $theme: ThemeMode
    $displayCurrency: String
    $acceptRemote: Boolean
    $primaryLocDescription: String
    $primaryLocGooglePlaceId: String
    $primaryLocMaxDistance: Int
  ) {
    updateUser: UpdateUser(
      uid: $uid
      displayName: $displayName
      email: $email
      locale: $locale
      theme: $theme
      displayCurrency: $displayCurrency
      acceptRemote: $acceptRemote
      primaryLocDescription: $primaryLocDescription
      primaryLocGooglePlaceId: $primaryLocGooglePlaceId
      primaryLocMaxDistance: $primaryLocMaxDistance
    ) {
      uid
      displayName
      email
      locale
      theme
      displayCurrency
      acceptRemote
      primaryLocDescription
      primaryLocGooglePlaceId
      primaryLocMaxDistance
    }
  }
`;

export const SUBMIT_CHALLENGE_SESSION = gql`
  mutation submitChallengeSession(
    $uid: ID!
    $input: ChallengeSessionSubmitInput!
  ) {
    ChallengeSessionSubmit(uid: $uid, input: $input) {
      id
      success
      submitted
      questId
    }
  }
`;

export const CALCULATE_REACHOUT = gql`
  mutation calculateReachoutReach($reachoutId: String!, $budget: Float) {
    calculateReachout(reachoutId: $reachoutId, budget: $budget) {
      reachoutId
      budget
      starts
      duration
      total
      reach
      connect
      upskill
      split
      impactScore
    }
  }
`;

export const CLAIM_REWARD = gql`
  mutation reward($matchId: String!) {
    fundingReward: claimReward(matchId: $matchId) {
      __typename
      claimId
      amount
      claimedAt
      expireAt
    }
  }
`;

export const ADD_CARD_TO_VAULT = gql`
  mutation addCardToVault($vaultOwnerId: VaultOwnerId!, $nonceClient: String!) {
    addPaymentMethod(vaultOwnerId: $vaultOwnerId, nonceClient: $nonceClient) {
      bin
      cardType
      cardholderName
      createdAt
      updatedAt
      expirationMonth
      expirationYear
      imageUrl
      token
      maskedNumber
      expirationDate
      last4
      default
    }
  }
`;

export const REMOVE_PAYMENT_METHOD_FROM_VAULT = gql`
  mutation deletePaymentMethod(
    $vaultOwnerId: VaultOwnerId!
    $paymentMethodToken: String!
  ) {
    paymentMethods: deletePaymentMethod(
      vaultOwnerId: $vaultOwnerId
      paymentMethodToken: $paymentMethodToken
    ) {
      bin
      cardType
      cardholderName
      createdAt
      updatedAt
      expirationMonth
      expirationYear
      imageUrl
      token
      maskedNumber
      expirationDate
      last4
      default
    }
  }
`;

export const UPDATE_PAYMENT_METHOD = gql`
  mutation updatePaymentMethod(
    $vaultOwnerId: VaultOwnerId!
    $paymentMethodToken: String!
    $update: PaymentMethodUpdateinput!
  ) {
    paymentMethods: updatePaymentMethod(
      vaultOwnerId: $vaultOwnerId
      paymentMethodToken: $paymentMethodToken
      update: $update
    ) {
      bin
      cardType
      cardholderName
      createdAt
      updatedAt
      expirationMonth
      expirationYear
      imageUrl
      token
      maskedNumber
      expirationDate
      last4
      default
    }
  }
`;

export const REACHOUT_PUBLISH = gql`
  mutation publishReachout(
    $reachoutId: String! # $payToken: String,
  ) {
    ro: publishReachout(
      reachoutId: $reachoutId # payToken: $payToken
    ) {
      reachoutId
      status
    }
  }
`;

export const CREATE_BUSINESS = gql`
  mutation createBusiness($id: ID!, $input: BusinessCreateInput) {
    BusinessCreate(id: $id, input: $input) {
      id
      displayName
      avatarUrl
    }
  }
`;

// Job Post

export const JOB_POST_CREATE = gql`
  mutation jobPostCreate($id: ID!, $input: jobPostInput!) {
    jobPost: JobPostCreate(id: $id, input: $input) {
      id
    }
  }
`;

export const JOB_POST_UPDATE = gql`
  mutation jobPostUpdate($id: ID!, $jobPostId: ID!, $input: jobPostInput!) {
    jobPost: JobPostCreate(id: $id, jobPostId: $jobPostId, input: $input) {
      id
    }
  }
`;

export const JOB_POST_DELETE = gql`
  mutation jobPostDelete($id: ID!, $jobPostId: ID!) {
    id: JobPostCreate(id: $id, jobPostId: $jobPostId) {
      id
    }
  }
`;
