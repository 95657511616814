import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

import Navigator from "./Navigator";
import Header from "./Header";
import Initializing from "./Initializing";

import { mainMenu } from "navigation/mainMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: theme.sizes.drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  main: {
    position: "relative",
    overflow: "auto",
    background: theme.palette.background.primary,
    height: "100vh",
    minWidth: 375,
  },
}));

function Main(props) {
  const { children, showHeader, initialized } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const classes = useStyles();
  const [mainContent, setMainContent] = useState();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOnNavigate = useCallback(() => {
    setMobileOpen(false);
  }, []);

  const handleMainContentRef = useCallback((node) => {
    setMainContent(node);
  }, []);

  if (!initialized) {
    return <Initializing />;
  }

  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="js">
          <Navigator
            menu={mainMenu}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            onNavigate={handleOnNavigate}
          />
        </Hidden>
        <Hidden xsDown implementation="css">
          <Navigator menu={mainMenu} />
        </Hidden>
      </nav>
      <div className={classes.app}>
        {showHeader && (
          <Header
            mainContentRef={mainContent}
            onDrawerToggle={handleDrawerToggle}
          />
        )}
        <main ref={handleMainContentRef} className={classes.main}>
          {children}
        </main>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  initialized: state.app.initialized,
});

export default connect(mapStateToProps)(Main);
