import { takeLatest, put, all, call, select } from "redux-saga/effects";
// import { push } from "connected-react-router";

import { hcn } from "services/hcn";

import * as c from "./constants";
import {
  jobPostCreateSuccessAction,
  jobPostCreateFailureAction,
  jobPostUpdateSuccessAction,
  jobPostUpdateFailureAction,
  jobPostDeleteSuccessAction,
  jobPostDeleteFailureAction,
  jobPostGetSuccessAction,
  jobPostGetFailureAction,
  jobPostsGetSuccessAction,
  jobPostsGetFailureAction,
  jobPostIntrosGetSuccessAction,
  jobPostIntrosGetFailureAction,
} from "./actions";

function* jobPostCreateRequest(action) {
  const { payload } = action;
  try {
    const { jobPost } = yield call(hcn.doCreateJobPost, {
      businessId: payload.businessId,
      input: payload.input,
    });
    yield put(jobPostCreateSuccessAction(jobPost));
  } catch (err) {
    if (err.message) {
      yield put(jobPostCreateFailureAction(err.message));
    }
  }
}

function* jobPostUpdateRequest(action) {
  const { payload } = action;
  try {
    const { jobPost } = yield call(hcn.doUpdateJobPost, {
      businessId: payload.businessId,
      jobPostId: payload.jobPostId,
      input: payload.input,
    });
    yield put(jobPostUpdateSuccessAction(jobPost));
  } catch (err) {
    if (err.message) {
      yield put(jobPostUpdateFailureAction(err.message));
    }
  }
}

function* jobPostDeleteRequest(action) {
  const { payload } = action;
  try {
    const { id } = yield call(hcn.doDeleteJobPost, {
      businessId: payload.businessId,
      jobPostId: payload.jobPostId,
    });
    yield put(jobPostDeleteSuccessAction(id));
  } catch (err) {
    if (err.message) {
      yield put(jobPostDeleteFailureAction(err.message));
    }
  }
}

function* jobPostGetRequest(action) {
  const { payload } = action;
  try {
    const { jobPost } = yield call(hcn.doGetJobPostById, {
      jobPostId: payload.jobPostId,
    });
    yield put(jobPostGetSuccessAction(jobPost));
  } catch (err) {
    if (err.message) {
      yield put(jobPostGetFailureAction(err.message));
    }
  }
}

function* jobPostsGetRequest(action) {
  try {
    const businessId = yield select((state) => state.space.data.id.identifier);
    const { jobPosts } = yield call(hcn.doGetJobPostsByBusinessId, {
      businessId,
    });
    yield put(jobPostsGetSuccessAction(jobPosts));
  } catch (err) {
    if (err.message) {
      yield put(jobPostsGetFailureAction(err.message));
    }
  }
}

function* jobPostIntrosGetReaquest(action) {
  const { payload } = action;
  const businessId = yield select((state) => state.space.data.id.identifier);
  const { introductions } = yield call(hcn.doGetJobPostIntroductions, {
    businessId,
    jobPostId: payload.jobPostId,
  });
  try {
    yield put(
      jobPostIntrosGetSuccessAction({
        introductions,
        jobPostId: payload.jobPostId,
      })
    );
  } catch (err) {
    if (err.message) {
      yield put(jobPostIntrosGetFailureAction(err.message));
    }
  }
}

export default function* watchJobPost() {
  yield all([
    takeLatest(c.JOB_POST_CREATE_REQUEST, jobPostCreateRequest),
    takeLatest(c.JOB_POST_UPDATE_REQUEST, jobPostUpdateRequest),
    takeLatest(c.JOB_POST_DELETE_REQUEST, jobPostDeleteRequest),
    takeLatest(c.JOB_POST_GET_REQUEST, jobPostGetRequest),
    takeLatest(c.JOB_POSTS_GET_REQUEST, jobPostsGetRequest),
    takeLatest(c.JOB_POST_INTROS_GET_REQUEST, jobPostIntrosGetReaquest),
  ]);
}
