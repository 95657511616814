export const SPACE_CREATE_REQUEST = "SPACE_CREATE_REQUEST";
export const SPACE_CREATE_SUCCESS = "SPACE_CREATE_SUCCESS";
export const SPACE_CREATE_FAILURE = "SPACE_CREATE_FAILURE";

export const SPACE_SET_REQUEST = "SPACE_SET_REQUEST";
export const SPACE_SET_SUCCESS = "SPACE_SET_SUCCESS";
export const SPACE_SET_FAILURE = "SPACE_SET_FAILURE";

export const SPACES_GET_REQUEST = "SPACES_GET_REQUEST";
export const SPACES_GET_SUCCESS = "SPACES_GET_SUCCESS";
export const SPACES_GET_FAILURE = "SPACES_GET_FAILURE";

export const SPACE_CLEANUP = "SPACE_CLEANUP";
