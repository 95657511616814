import React, { useCallback, forwardRef } from "react";

import MuiMenuItem from "@material-ui/core/MenuItem";

const MenuItem = forwardRef((props, ref) => {
  const { item, onClick = () => {}, ...rest } = props;

  const handleOnClick = useCallback(() => {
    onClick(item);
  }, [item, onClick]);

  return <MuiMenuItem ref={ref} onClick={handleOnClick} {...rest} />;
});

export default MenuItem;
