import React, { useMemo } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  singleLineText: {
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
};

function TitleCollection(props) {
  const { classes, items } = props;
  const titles = useMemo(() => {
    let string = "";
    for (let i = 0; i < items.length; i++) {
      if (items[i].title) {
        string = string + items[i].title;
        if (items.length - 1 > i) {
          string = string + ", ";
        }
      }
    }
    return string;
  }, [items]);

  return <div className={classes.singleLineText}>{titles}</div>;
}

export default withStyles(styles)(TitleCollection);
