import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Switch, Redirect } from "react-router-dom";
import RouteWithLayout from "./RouteWithLayout";

import * as ROUTE from "./constants";
import { appInitRequestAction } from "containers/app/actions";

import { Main, Simple } from "layout";

// Screens
import Dashboard from "screens/Dashboard";
import Settings from "screens/Settings";
import MyInbox from "screens/MyInbox";
import NotFound from "screens/NotFound";
import Spaces from "screens/Spaces";
import SpaceCreate from "screens/SpaceCreate";
import JobPost from "screens/JobPost";
import JobPosts from "screens/JobPosts";

const AppNavigation = (props) => {
  const { appInitRequest } = props;

  useEffect(() => {
    appInitRequest();
  }, [appInitRequest]);

  return (
    <Switch>
      <RouteWithLayout layout={Main} showHeader exact path={ROUTE.DASHBOARD}>
        <Dashboard />
      </RouteWithLayout>
      <RouteWithLayout layout={Main} path={ROUTE.SETTINGS}>
        <Settings />
      </RouteWithLayout>
      <RouteWithLayout layout={Main} path={ROUTE.MY_INBOX}>
        <MyInbox />
      </RouteWithLayout>
      <RouteWithLayout layout={Main} showHeader path={ROUTE.JOB_POSTS + "/:id"}>
        <JobPost />
      </RouteWithLayout>
      <RouteWithLayout layout={Main} showHeader path={ROUTE.JOB_POSTS}>
        <JobPosts />
      </RouteWithLayout>
      <RouteWithLayout layout={Simple} path={ROUTE.SPACE_CREATE}>
        <SpaceCreate />
      </RouteWithLayout>
      <RouteWithLayout layout={Simple} exact path={ROUTE.SPACES}>
        <Spaces />
      </RouteWithLayout>
      <RouteWithLayout layout={Main} path={ROUTE.NOT_FOUND}>
        <NotFound
          pageTitle={404}
          actionPath={ROUTE.DASHBOARD}
          actionTitle="Go to dashboard"
        />
      </RouteWithLayout>
      <Redirect to={ROUTE.NOT_FOUND} />
    </Switch>
  );
};

const actions = (dispatch) => ({
  appInitRequest: () => dispatch(appInitRequestAction()),
});

export default connect(null, actions)(AppNavigation);
