import React, { useEffect, useState, useRef, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { Form, Field } from "react-final-form";
import * as yup from "yup";

import { fb } from "services/firebase";
import { validateFormValues } from "forms";

const phoneNumberSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .matches(/\+\d+/, "Please enter a valid number")
    .required("Please enter your mobile number"),
});

const otpSchema = yup.object().shape({
  otp: yup.string().length(6).required("Please enter the code"),
});

const useStyles = makeStyles((theme) => ({
  container: {
    height: ({ size, padding }) => size + theme.spacing(padding),
    width: ({ size, padding }) => size + theme.spacing(padding),
    borderRadius: ({ borderRadius }) => theme.spacing(borderRadius),
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
}));

class Queue {
  static queue = [];
  static workingOnPromise = false;

  static enqueue(promiseGenerator) {
    this.queue.push({
      promiseGenerator,
    });
    this.dequeue();
  }

  static dequeue() {
    if (this.workingOnPromise) {
      return false;
    }

    const item = this.queue.shift();

    if (!item) {
      return false;
    }

    this.workingOnPromise = true;

    item
      .promiseGenerator()
      .catch((err) => {
        console.error(err);
        this.queue = [];
      })
      .finally(() => {
        this.workingOnPromise = false;
        this.dequeue();
      });

    return true;
  }
}

function PhoneNumberLogin(props) {
  const classes = useStyles(props);
  const [tel, setTel] = useState("");
  const verifier = useRef(null);
  const recapchaRef = useRef();
  const confirmationResult = useRef(null);
  const [nextStep, setNextStep] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    verifier.current = new fb.auth.RecaptchaVerifier(recapchaRef.current, {
      size: "invisible",
    });
    Queue.enqueue(() => verifier.current.verify());

    return () => {
      verifier.current.clear();
    };
  }, []);

  const handleTelSubmit = useCallback((values) => {
    Queue.enqueue(() =>
      fb
        .auth()
        .signInWithPhoneNumber(values.phoneNumber, verifier.current)
        .then((result) => {
          confirmationResult.current = result;
          console.log("OTP sent");
        })
        .catch((e) => {
          alert("Invalid phone number");
          setNextStep(false);
          throw e;
        })
        .finally(() => {
          setLoading(false);
        })
    );

    setTel(values.phoneNumber);
    setNextStep(true);
  }, []);

  const handleOTPSubmit = useCallback((values) => {
    setLoading(true);

    Queue.enqueue(() =>
      confirmationResult.current
        .confirm(values.otp)
        .then((authUser) => {
          if (authUser.additionalUserInfo.isNewUser) {
            authUser.user.delete().then(() => {
              console.log("User deleted");
            });
          }
        })
        .catch((e) => {
          alert("Invalid OTP");
          throw e;
        })
        .finally(() => {
          setLoading(false);
        })
    );
  }, []);

  return (
    <div className={classes.container}>
      <Box
        position="absolute"
        right={0}
        top={0}
        left={0}
        bottom={0}
        bgcolor={"background.overlay.light"}
        p={4.5}
      >
        {!nextStep && (
          <Form
            validate={validateFormValues(phoneNumberSchema)}
            onSubmit={handleTelSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field name="phoneNumber">
                  {({ input, meta }) => (
                    <>
                      <Typography variant="h6" gutterBottom>
                        Enter your mobile number
                      </Typography>
                      <TextField
                        variant="outlined"
                        error={meta.invalid && meta.touched}
                        helperText={
                          meta.error && meta.touched
                            ? meta.error
                            : "By continuing you may receive an SMS for verification. Messages and data rates apply."
                        }
                        {...input}
                      />
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      ></Typography>
                    </>
                  )}
                </Field>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  style={{ float: "right" }}
                  endIcon={<Icon>send</Icon>}
                >
                  Next
                </Button>
              </form>
            )}
          />
        )}
        {nextStep && (
          <Form
            validate={validateFormValues(otpSchema)}
            onSubmit={handleOTPSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field name="otp">
                  {({ input, meta }) => (
                    <>
                      <Typography variant="h6" gutterBottom>
                        Verify your phone number
                      </Typography>
                      <TextField
                        variant="outlined"
                        error={meta.invalid && meta.touched}
                        helperText={
                          meta.error && meta.touched
                            ? meta.error
                            : `Enter the 6-digit code sent to you at: ${tel}`
                        }
                        {...input}
                      />
                    </>
                  )}
                </Field>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  style={{ float: "right" }}
                  endIcon={<Icon>send</Icon>}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Next"}
                </Button>
              </form>
            )}
          />
        )}
      </Box>
      <div ref={recapchaRef} id="recaptcha-container" />
    </div>
  );
}

PhoneNumberLogin.defaultProps = {
  size: 250,
  padding: 4,
  borderRadius: 2,
};

export default PhoneNumberLogin;
