import React from "react";
import OffsetHeader from "components/OffsetHeader";

function Settings() {
  return (
    <div>
      <OffsetHeader />
      <h1>Settings</h1>
    </div>
  );
}

export default Settings;
