export const NOT_FOUND = "/404";
export const ROOT = "/";
export const LOGIN = "/login";
export const SETTINGS = "/settings";
export const DASHBOARD = "/dashboard";
export const TERMS_OF_SERVICE = "#";
export const PRIVACY_POLICY = "#";
export const MY_INBOX = "/my_inbox";
export const SPACES = "/";
export const SPACE_CREATE = "/space/create";
export const JOB_POSTS = "/jobposts";

export const HOME = "/b";
