import MuiButtonBase from "@material-ui/core/ButtonBase";
import { useCallback } from "react";

function ButtonBase(props) {
  const { onClick, item, ...rest } = props;

  const handleOnClick = useCallback(() => {
    onClick(item);
  }, [item, onClick]);
  return <MuiButtonBase onClick={handleOnClick} {...rest} />;
}

ButtonBase.defaultProps = {
  onClick: () => {},
  item: null,
};

export default ButtonBase;
