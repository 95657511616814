import * as c from "./constants";

// CREATE
export const spaceCreateRequestAction = () => ({
  type: c.SPACE_CREATE_REQUEST,
  payload: null,
  error: false,
});

export const spaceCreateSuccesAction = (space) => ({
  type: c.SPACE_CREATE_SUCCESS,
  payload: {
    ...space,
  },
  error: false,
});

export const spaceCreateFailureAction = (message) => ({
  type: c.SPACE_CREATE_FAILURE,
  payload: {
    message,
  },
  error: true,
});

// SET
export const spaceSetRequestAction = (space, meta) => ({
  type: c.SPACE_SET_REQUEST,
  payload: {
    ...space,
  },
  meta: {
    ...meta,
  },
  error: false,
});

export const spaceSetSuccesAction = (space) => ({
  type: c.SPACE_SET_SUCCESS,
  payload: {
    ...space,
  },
  error: false,
});

export const spaceSetFailureAction = (message) => ({
  type: c.SPACE_SET_FAILURE,
  payload: {
    message,
  },
  error: true,
});

// GET ALL
export const spacesGetRequestAction = () => ({
  type: c.SPACES_GET_REQUEST,
  payload: {},
  error: false,
});

export const spacesGetSuccessAction = (spaces) => ({
  type: c.SPACES_GET_SUCCESS,
  payload: {
    spaces,
  },
  error: false,
});

export const spacesGetFailureAction = (message) => ({
  type: c.SPACES_GET_FAILURE,
  payload: {
    message,
  },
  error: true,
});

// CLEANUP
export const spaceCleanupAction = () => ({
  type: c.SPACE_CLEANUP,
  payload: null,
  error: false,
});
