import React from "react";
import OffsetHeader from "components/OffsetHeader";

function MyInbox() {
  return (
    <div>
      <OffsetHeader />
      <h1>MyInbox</h1>
    </div>
  );
}

export default MyInbox;
